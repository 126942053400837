(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const Misc = {

    shootingStars() {
      const header = $('.c-header__bg-image');

      if (header.length) {
        header.addClass('shootstars');
        // eslint-disable-next-line no-undef
        const shootingStarObj = new ShootingStar(".shootstars");
        shootingStarObj.launch(15); //argument is time between stars
      }
    },

    homePageSlider() {
      const slider = $('.js-home-page-slider');

      if (slider.length) {
        slider.owlCarousel({
          items: 1,
          nav: false,
          dots: true,
          navSpeed: 1000,
          dotsSpeed: 1000,
          autoplaySpeed: 1000,
          loop: true,
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          animateOut: 'fadeOut',
          video: true,
          autoHeight: true,
          mouseDrag: false,
        });
      }
    },

    videoBox() {
      const iframes = $('iframe');

      if (iframes.length) {
        $.each(iframes, (i, el) => {
          const iframe = $(el);

          if (iframe.attr('src').indexOf('youtube') !== -1 && !iframe.parent().hasClass('c-video__box')) {
            iframe.wrap('<div class="c-video__box"></div>');
          }
        });
      }
    },

    stickyNav() {
      const menu = $('.c-header--fixed .c-header__nav');

      if (menu.length) {
        const menuPos = menu.position();

        $(window).on('scroll', () => {
          const scroll = $(window).scrollTop();

          if (scroll >= menuPos.top) {
            menu.addClass('is-sticky');
          } else {
            menu.removeClass('is-sticky');
          }
        });
      }
    },

    init() {
      $(document).ready( () => {
        Misc.shootingStars();
        Misc.homePageSlider();
        Misc.videoBox();
        Misc.stickyNav();
      });

      $(window).on('load', () => {
      });

      $(window).on('resize', () => {
      });
    }

  }

  module.exports = Misc;

})(jQuery, window, document);
